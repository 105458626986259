// Generated by Framer (e010222)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getLoadingLazyAtYPosition, Image, Link, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const serializationHash = "framer-C3fZu"

const variantClassNames = {rpWeVc5MS: "framer-v-subena"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {delay: 0, duration: 0.7, ease: [0.99, 0, 0.09, 1], type: "tween"}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, link, logo, width, ...props}) => { return {...props, E9Hn8GJQT: link ?? props.E9Hn8GJQT, wE4Cmsd1l: logo ?? props.wE4Cmsd1l ?? {src: "https://framerusercontent.com/images/J7UdRSuHocPUabpYzjH0RWKqME.png?scale-down-to=512", srcSet: "https://framerusercontent.com/images/J7UdRSuHocPUabpYzjH0RWKqME.png?scale-down-to=512 512w,https://framerusercontent.com/images/J7UdRSuHocPUabpYzjH0RWKqME.png 690w"}} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;logo?: {src: string; srcSet?: string; alt?: string};link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, wE4Cmsd1l, E9Hn8GJQT, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "rpWeVc5MS", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={E9Hn8GJQT} nodeId={"rpWeVc5MS"} openInNewTab={false} smoothScroll><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-subena", className, classNames)} framer-fl12w9`} data-framer-name={"Default"} layoutDependency={layoutDependency} layoutId={"rpWeVc5MS"} ref={ref ?? ref1} style={{...style}}><Image background={{alt: "", fit: "fit", loading: getLoadingLazyAtYPosition(((componentViewport?.y || 0) + (0 + ((((componentViewport?.height || 112) - 0) - 112) / 2)))), pixelHeight: 20, pixelWidth: 20, sizes: "244px", ...toResponsiveImage(wE4Cmsd1l), ...{ positionX: "left", positionY: "center" }}} className={"framer-1vn75ce"} data-framer-name={"Logo"} layoutDependency={layoutDependency} layoutId={"hi9jPJpSK"}/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-C3fZu.framer-fl12w9, .framer-C3fZu .framer-fl12w9 { display: block; }", ".framer-C3fZu.framer-subena { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: flex-start; overflow: hidden; padding: 0px; position: relative; text-decoration: none; width: 180px; }", ".framer-C3fZu .framer-1vn75ce { flex: none; height: 112px; position: relative; width: 244px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-C3fZu.framer-subena { gap: 0px; } .framer-C3fZu.framer-subena > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-C3fZu.framer-subena > :first-child { margin-left: 0px; } .framer-C3fZu.framer-subena > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 112
 * @framerIntrinsicWidth 180
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]}}}
 * @framerVariables {"wE4Cmsd1l":"logo","E9Hn8GJQT":"link"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerobgz9hShU: React.ComponentType<Props> = withCSS(Component, css, "framer-C3fZu") as typeof Component;
export default Framerobgz9hShU;

Framerobgz9hShU.displayName = "Brand";

Framerobgz9hShU.defaultProps = {height: 112, width: 180};

addPropertyControls(Framerobgz9hShU, {wE4Cmsd1l: {__defaultAssetReference: "data:framer/asset-reference,J7UdRSuHocPUabpYzjH0RWKqME.png?originalFilename=arcadia+logo.png&preferredSize=auto", title: "Logo", type: ControlType.ResponsiveImage}, E9Hn8GJQT: {title: "Link", type: ControlType.Link}} as any)

addFonts(Framerobgz9hShU, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})