import {
    addPropertyControls,
    ControlType,
    RenderTarget,
    // @ts-ignore
    ResolveLinks,
    // @ts-ignore
    useQueryData,
} from "framer"
import { cloneElement, useEffect, useState, useRef, Children } from "react"
import {
    Message,
    usePaginationState,
    useFilterState,
    useFavouriteState,
    useSearchState,
    useRandomizationState,
} from "https://framer.com/m/SuperfieldsShared-hv54.js@iVCdy5TNWtnCai5Qvhjj"
import Masonry from "https://framer.com/m/Masonry-u5eW.js@3By4VvLRRlsqmHmoICUC"

const FAVOURITES_FILTER_ID = "[{(FAVOURITES)}]"
const DELIMITER = "_&%#|_"

/**
 * @framerSupportedLayoutWidth any-prefer-fixed
 * @framerSupportedLayoutHeight any
 * @framerIntrinsicWidth 600
 */
export default function Superfields(props) {
    const {
        superfieldsId,
        layout,
        componentConfig,
        pagination,
        paginationType,
        itemsPerPage,
        filtering,
        favouriting,
        randomize,
        cmsCollectionName,
        slugFieldName,
        favouritesOnly,
    } = props
    const collectionList = props.collectionList?.[0]
    const emptyState = props.emptyState?.[0]
    const query =
        collectionList?.props?.query ??
        collectionList?.props?.children?.props?.query
    const queryData = query?.from?.data
    const isCanvas = RenderTarget.current() === RenderTarget.canvas

    const scrollUpRef = useRef(null)

    const [paginationState, setPaginationState] = usePaginationState()
    const [filterState, setFilterState] = useFilterState()
    const [favouriteState, setFavouriteState] = useFavouriteState()
    const [searchState, setSearchState] = useSearchState()
    const [randomizationState, setRandomizationState] = useRandomizationState()

    const filters = filterState[superfieldsId]

    // Get property controls
    const propertyControlsByName = {}
    const propertyControlsById = queryData?.propertyControls || {}

    for (const id in propertyControlsById) {
        const control = propertyControlsById[id]
        propertyControlsByName[control.title] = { id, ...control }
    }

    const slugFieldId = propertyControlsByName[slugFieldName]?.id ?? ""

    let select = Array.isArray(query?.select) ? [...query.select] : []
    if (query?.select && (filters || props.search)) {
        let usedFieldNames = []

        if (filters) {
            for (const fieldName of Object.keys(filters)) {
                const filter = filters[fieldName]
                if (filter.fieldType == "multipleOptions") {
                    usedFieldNames.push(...fieldName.split(DELIMITER))
                } else {
                    usedFieldNames.push(fieldName)
                }
            }
        }

        for (const fieldName of props.searchFields) {
            usedFieldNames.push(fieldName)
        }

        usedFieldNames = usedFieldNames
            .map((value) => propertyControlsByName[value]?.id || null)
            .filter((value) => value !== null)

        for (const value of select) {
            usedFieldNames.push(value.name)
        }

        select = Array.from(new Set(usedFieldNames)).map((name) => ({
            name,
            type: "Identifier",
        }))
    }

    // Get CMS data
    const originalData = query ? useQueryData({ ...query, select }) : []
    let data = originalData

    const originalDataMap = {}
    if (!isCanvas) {
        if (Array.isArray(queryData)) {
            for (const item of queryData) {
                originalDataMap[item.id] = item
            }
        } else if (Array.isArray(data)) {
            for (const item of data) {
                originalDataMap[item.id] = item
            }
        }
    }

    const [dataOrder, setDataOrder] = useState(
        Array.from({ length: originalData?.length }, (_, i) => i)
    )

    const updatePaginationState = () => {
        if (Array.isArray(data)) {
            const totalPages = Math.ceil(data.length / itemsPerPage)

            setPaginationState({
                [superfieldsId]: {
                    page: 0,
                    totalPages,
                    itemsPerPage,
                    totalItems: data.length,
                    paginationType,
                    active: pagination,
                    onPaginationChange,
                },
            })
        }
    }

    useEffect(() => {
        updatePaginationState()
        setFilterState({})

        setRandomizationState({
            [superfieldsId]: {
                seed: props.randomize && !isCanvas ? Math.random() : null,
            },
        })
    }, [])

    useEffect(() => {
        const seed = randomizationState[superfieldsId]?.seed ?? null
        if (seed && !isCanvas) {
            const newDataOrder = Array.from(
                { length: originalData.length },
                (_, i) => i
            )
            shuffle(newDataOrder, seed)
            setDataOrder(newDataOrder)
        }
    }, [randomize, randomizationState[superfieldsId]?.seed, originalData])

    function onPaginationChange() {
        if (
            props.scrollUp &&
            scrollUpRef.current &&
            paginationType == "prevNextButtons"
        ) {
            const rect = scrollUpRef.current.getBoundingClientRect()
            const topIsInView = rect.top >= 0 && rect.top <= window.innerHeight

            if (!topIsInView) {
                scrollUpRef.current.scrollIntoView({ behavior: "smooth" })
            }
        }
    }

    // Filtering
    if (!isCanvas && filters) {
        let newData = [...data]

        for (const fieldName in filters) {
            if (fieldName == FAVOURITES_FILTER_ID) {
                continue
            }

            const filter = filters[fieldName]
            const value = filter?.value
            const conditionContains = filter?.condition === "contains"

            const fields = []
            if (filter.fieldType === "multipleOptions") {
                for (const n of fieldName.split(DELIMITER)) {
                    const field = propertyControlsByName[n.trim()]
                    if (field) {
                        fields.push(field)
                    }
                }
            } else {
                const field = propertyControlsByName[fieldName]
                if (field) {
                    fields.push(field)
                }
            }

            if (
                filter &&
                fields.length &&
                value != null &&
                value != undefined
            ) {
                const filteredData = []

                for (const cmsItem of newData) {
                    let foundMatch = false

                    const originalDataItem = originalDataMap[cmsItem.id]

                    for (const field of fields) {
                        const dataFieldValue = originalDataItem[field.id]

                        if (typeof value == "string" && filter.multiSelect) {
                            const values = value.split(DELIMITER)

                            switch (field.type) {
                                case "string":
                                    if (conditionContains) {
                                        for (const value of values) {
                                            if (
                                                dataFieldValue.includes(value)
                                            ) {
                                                foundMatch = true
                                                break
                                            }
                                        }
                                    } else {
                                        if (values.includes(dataFieldValue)) {
                                            foundMatch = true
                                        }
                                    }
                                    break
                                case "enum":
                                    for (const v of values) {
                                        if (
                                            dataFieldValue ==
                                            field.options?.[
                                                field.optionTitles?.indexOf(v)
                                            ]
                                        ) {
                                            foundMatch = true
                                        }
                                    }
                                    break
                            }
                        } else {
                            switch (field.type) {
                                case "boolean":
                                    if (dataFieldValue == value) {
                                        foundMatch = true
                                    }
                                    break
                                case "string":
                                    if (conditionContains) {
                                        if (
                                            originalDataItem[
                                                field.id
                                            ]?.includes(value)
                                        ) {
                                            foundMatch = true
                                        }
                                    } else {
                                        if (dataFieldValue == value) {
                                            foundMatch = true
                                        }
                                    }
                                    break
                                case "enum":
                                    if (
                                        dataFieldValue ==
                                        field.options?.[
                                            field.optionTitles?.indexOf(value)
                                        ]
                                    )
                                        foundMatch = true
                                    break
                            }
                        }

                        if (foundMatch) {
                            filteredData.push(cmsItem)
                            break
                        }
                    }
                }

                newData = filteredData
            }
        }

        data = newData
    }

    // Favourites filter
    const favouriteFilterValue = filters?.[FAVOURITES_FILTER_ID]?.value
    if (
        !isCanvas &&
        slugFieldId &&
        data &&
        (favouritesOnly || typeof favouriteFilterValue === "boolean")
    ) {
        const favourites = favouriteState[cmsCollectionName]
        if (Array.isArray(favourites)) {
            if (favouriteFilterValue === true || favouritesOnly) {
                data = data.filter((item) =>
                    favourites.includes(item[slugFieldId])
                )
            } else {
                data = data.filter(
                    (item) => !favourites.includes(item[slugFieldId])
                )
            }
        } else if (favouritesOnly) {
            // Hide all items in optimized version of website if favourites only is enabled
            data = []
        }
    }

    // Search
    let missingSearchFieldError = ""
    const searchText = searchState[superfieldsId]?.trim().toLowerCase() ?? ""
    if (searchText.length && data) {
        const otherData = []

        // 0: Hidden
        // 1: Includes text
        // 2: Includes full word
        // 3: Starts with
        // 4: Exact match
        const levels = [[...data], [], [], [], []]
        const itemLevels = new Array(data.length).fill(0)

        for (let i = 0; i < props.searchFields.length; i++) {
            const fieldName = props.searchFields[i]
            const property = propertyControlsByName[fieldName]

            if (!property) {
                missingSearchFieldError = fieldName
                break
            }

            const propertyID = property.id
            const propertyType = property.type
            const optionMap = {}

            const propIsString = propertyType == "string"
            const propIsEnum = propertyType == "enum"
            const propIsNumber = propertyType == "number"

            if (
                propertyType == "enum" &&
                property.options &&
                property.optionTitles
            ) {
                for (let i = 0; i < property.options.length; i++) {
                    optionMap[property.options[i]] =
                        property.optionTitles[i].toLowerCase()
                }
            }

            for (const ii in data) {
                const cmsItem = data[ii]
                const value = originalDataMap[cmsItem.id]?.[propertyID]

                if (value == undefined || value == null) {
                    continue
                }

                let text = propIsString
                    ? value.toLowerCase()
                    : propIsEnum
                      ? optionMap[value]
                      : propIsNumber
                        ? String(value)
                        : ""

                let level = 0
                if (text == searchText) {
                    level = 4
                } else if (text.includes(searchText)) {
                    if (text.startsWith(searchText)) {
                        level = 3
                    } else if (
                        (" " + text + " ").includes(" " + searchText + " ")
                    ) {
                        level = 2
                    } else {
                        level = 1
                    }
                }

                // Move item to new level if it's a higher level
                const oldLevel = itemLevels[ii]
                if (level > oldLevel) {
                    // Remove from old level
                    const oldLevelItems = []
                    for (const item of levels[oldLevel]) {
                        if (item != cmsItem) {
                            oldLevelItems.push(item)
                        }
                    }
                    levels[oldLevel] = oldLevelItems

                    // Add to new level
                    levels[level].push(cmsItem)
                    itemLevels[ii] = level
                }
            }
        }

        if (!missingSearchFieldError.length) {
            data = [
                ...levels[4],
                ...levels[3],
                ...levels[2],
                ...levels[1],
                ...otherData,
            ]
        }
    }

    if (data?.length !== paginationState[superfieldsId]?.totalItems) {
        updatePaginationState()
    }

    // Number of items removed from beginning of array by pagination
    let childrenStartOffset = 0

    // Pagination and randomization are performed after filtering and search

    let items = data

    // Randomization
    if (!isCanvas && !searchText && randomizationState[superfieldsId]?.seed) {
        let orderedItems = new Array(originalData.length)

        for (let i = 0; i < dataOrder.length; i++) {
            orderedItems[dataOrder[i]] = originalData[i]
        }

        items = orderedItems.filter((item) => items.includes(item))
    }

    // Pagination
    if (!isCanvas && pagination) {
        const state = paginationState[superfieldsId]
        if (state) {
            switch (paginationType) {
                case "prevNextButtons":
                    items = items.slice(
                        state.page * itemsPerPage,
                        state.page * itemsPerPage + itemsPerPage
                    )
                    childrenStartOffset = state.page * itemsPerPage
                    break
                case "loadMoreButton":
                case "infiniteScroll":
                    items = items.slice(0, (state.page + 1) * itemsPerPage)
                    break
            }
        }
    }

    let showNotComponentChildError = false

    let children = []
    let className = ""
    if (!isCanvas && collectionList && query) {
        const { offset, limit, ...otherQuery } = query

        let childrenFunction = null
        const clpc = collectionList.props?.children
        if (typeof clpc == "function") {
            childrenFunction = clpc
        } else if (typeof clpc?.props?.children == "function") {
            childrenFunction = clpc?.props?.children
        }

        if (childrenFunction) {
            let clChildren = childrenFunction(items)
            if (Array.isArray(clChildren)) {
                children = clChildren
            } else if (Array.isArray(clChildren?.props?.children?.[0])) {
                children = clChildren.props.children[0]
            } else if (Array.isArray(clChildren?.props?.children)) {
                children = clChildren.props.children
            }

            if (clChildren?.props?.className) {
                className = clChildren.props.className
            }
        }

        if (
            componentConfig &&
            componentConfig.variant.length > 0 &&
            children?.length
        ) {
            const newChildren = []
            let isComponentWithVariants = true

            for (let i = 0; i < children.length; i++) {
                const child = children[i]
                const component = (
                    <ComponentVariantSwitch
                        child={child}
                        componentConfig={componentConfig}
                    />
                )
                if (component === child) {
                    isComponentWithVariants = false
                    break
                } else {
                    newChildren.push(component)
                }
            }

            if (isComponentWithVariants) {
                children = newChildren
            }
        }
    }

    if (!collectionList) {
        return (
            <Message
                title="Connect a CMS Collection List"
                subtitle="Drag the handle on the right side to a Collection List or select from the dropdown list. The Collection List must be outside of a page to be connected."
            />
        )
    }

    if (!isCanvas && !query) {
        return (
            <Message
                title="Connect a CMS Collection List"
                subtitle="The layer that's connected isn't a Collection List. Make sure the Collection List isn't inside of any other layers or components when connecting it."
            />
        )
    }

    if (favouriting && cmsCollectionName == "") {
        return (
            <Message
                title="Enter a CMS Collection Name"
                subtitle='Write the name of the CMS collection in the "CMS Collection Name" property. The name is used for saving favourites as a cookie and for matching with favourite buttons.'
            />
        )
    }

    if (favouriting && slugFieldName == "") {
        return (
            <Message
                title="Enter a Slug Field Name"
                subtitle='Write the name of the field in the "Slug Field Name" property. "Slug" is the default value unless you renamed it.'
            />
        )
    }

    if (showNotComponentChildError) {
        return (
            <Message
                title="Collection List item is not a component with variants."
                subtitle="To use variant overrides, make sure the only item in the Collection List is a component with variants. Otherwise, remove the component variant override."
            />
        )
    }

    if (missingSearchFieldError.length) {
        return (
            <Message
                title={`"${missingSearchFieldError}" field does not exist in your CMS collection`}
                subtitle={`The field "${missingSearchFieldError}" is in the search fields list, but there are no fields in your CMS collection with that name. Edit the field name in the search fields list, or add a new CMS field with that name.`}
            />
        )
    }

    let layoutStyle = {}
    if (layout) {
        const padding = layout.paddingIsMixed
            ? `${layout.paddingTop}px ${layout.paddingRight}px ${layout.paddingBottom}px ${layout.paddingLeft}px`
            : `${layout.padding}px`

        switch (layout.type) {
            case "stack":
                const isVertical = layout.direction == "vertical"
                layoutStyle = {
                    display: "flex",
                    flexDirection: isVertical ? "column" : "row",
                    flexWrap: layout.wrap ? "wrap" : "nowrap",
                    alignItems: isVertical ? layout.alignV : layout.alignH,
                    justifyContent: layout.distribute,
                    gap: layout.gap,
                    padding,
                }
                break
            case "grid":
                // Columns
                let gridTemplateColumns = ""
                if (layout.columns == "auto") {
                    if (layout.gridWidthType == "min") {
                        gridTemplateColumns = `repeat(auto-fill, minmax(${layout.gridWidth}px, 1fr))`
                    } else {
                        // "fixed"
                        gridTemplateColumns = `repeat(auto-fill, ${layout.gridWidth}px)`
                    }
                } else {
                    if (layout.gridWidthType == "min") {
                        gridTemplateColumns = `repeat(${layout.columnCount}, minmax(${layout.gridWidth}px, 1fr))`
                    } else {
                        // "fixed"
                        gridTemplateColumns = `repeat(${layout.columnCount}, ${layout.gridWidth}px)`
                    }
                }

                // Rows
                let gridAutoRows = ""
                switch (layout.gridHeightType) {
                    case "fixed":
                        gridAutoRows = `${layout.gridHeight}px`
                        break
                    case "fit":
                        gridAutoRows = "min-content"
                        break
                }

                layoutStyle = {
                    display: "grid",
                    gridTemplateColumns,
                    gridAutoRows,
                    justifyContent: layout.gridAlign,
                    alignItems: layout.gridAlignV,
                    columnGap: layout.gapH,
                    rowGap: layout.gapV,
                    padding,
                    placeContent: "start end",
                }
                break
        }
    }

    const isMasonry = layout?.type == "masonry"

    // Canvas view
    if (isCanvas) {
        if (collectionList) {
            if (isMasonry) {
                const htmlElement =
                    collectionList.props?.__node?.cache?.htmlElement
                if (htmlElement) {
                    let reactFiberKey = ""
                    for (const key of Object.keys(htmlElement)) {
                        if (key.startsWith("__reactFiber")) {
                            reactFiberKey = key
                        }
                    }

                    // Solution for getting React children from CMS collection on canvas by Fehmi Ozuseven
                    let children =
                        htmlElement[reactFiberKey]?.memoizedProps?.children?.[0]
                            ?.props?.children?.props?.children?.[1]?.props
                            ?.children?.[0]?.props?.children?.[0]?.props
                            ?.children

                    if (children) {
                        return (
                            <Masonry
                                columns={layout.masonryColumns}
                                gap={`${layout.gapV}px ${layout.gapH}px`}
                                alignment={
                                    layout.masonryItemWidth === "fit"
                                        ? layout.masonryAlign
                                        : "stretch"
                                }
                                autoArrange={true}
                                reverse={false}
                            >
                                {children}
                            </Masonry>
                        )
                    }
                }
            } else {
                const id =
                    props.id ??
                    "a" + String(Math.floor(Math.random() * 999999999))
                return (
                    <>
                        {cloneElement(collectionList, {
                            id,
                            style: {
                                ...collectionList.props?.style,
                                ...props.style,
                                ...layoutStyle,
                            },
                        })}
                        {pagination && (
                            <style>{`#${id} > *:nth-child(n+${
                                itemsPerPage + 1
                            }) {
                                display: none !important;
                            }`}</style>
                        )}
                        {isMasonry && (
                            <style>{`#${id} > * {
                                align-self: flex-start !important;
                                justify-self: ${layout?.masonryAlign} !important;
                            }`}</style>
                        )}
                    </>
                )
            }
        } else {
            return <div style={{ height: 100 }} />
        }
    }

    // Empty state
    if (!items?.length) {
        if (emptyState) {
            return cloneElement(emptyState, {
                style: {
                    ...emptyState.props.style,
                    ...props.style,
                },
            })
        } else {
            return <div />
        }
    }

    const elements = children.map((child, i) => (
        <child.type {...child.props} key={child.props.id} />
    ))

    return (
        <div
            {...collectionList.props}
            style={{
                ...collectionList?.props.style,
                ...props.style,
                position: "relative",
                overflow:
                    pagination && paginationType == "infiniteScroll"
                        ? "hidden auto"
                        : undefined,
                ...layoutStyle,
            }}
            className={isMasonry ? "" : className}
        >
            {isMasonry ? (
                <Masonry
                    columns={layout.masonryColumns}
                    gap={`${layout.gapV}px ${layout.gapH}px`}
                    alignment={
                        layout.masonryItemWidth === "fit"
                            ? layout.masonryAlign
                            : "stretch"
                    }
                    autoArrange={true}
                    reverse={false}
                >
                    {elements}
                </Masonry>
            ) : (
                elements
            )}
            {props.scrollUp && (
                <div
                    ref={scrollUpRef}
                    style={{
                        position: "absolute",
                        top: props.scrollUp.offset,
                        pointerEvents: "none",
                    }}
                />
            )}
        </div>
    )
}

addPropertyControls(Superfields, {
    superfieldsId: {
        type: ControlType.Number,
        defaultValue: 0,
        step: 1,
        min: 0,
        displayStepper: true,
        title: "ID",
    },
    collectionList: {
        type: ControlType.ComponentInstance,
    },
    emptyState: {
        type: ControlType.ComponentInstance,
    },
    layout: {
        type: ControlType.Object,
        optional: true,
        controls: {
            type: {
                type: ControlType.Enum,
                defaultValue: "stack",
                options: ["stack", "grid", "masonry"],
                optionTitles: ["Stack", "Grid", "Masonry"],
                displaySegmentedControl: true,
                segmentedControlDirection: "vertical",
            },
            direction: {
                type: ControlType.Enum,
                defaultValue: "vertical",
                options: ["horizontal", "vertical"],
                optionTitles: ["Horizontal", "Vertical"],
                optionIcons: ["direction-horizontal", "direction-vertical"],
                displaySegmentedControl: true,
                hidden: (props) => props.type != "stack",
            },
            distribute: {
                type: ControlType.Enum,
                defaultValue: "start",
                options: [
                    "start",
                    "center",
                    "end",
                    "space-between",
                    "space-around",
                    "space-evenly",
                ],
                optionTitles: [
                    "Start",
                    "Center",
                    "End",
                    "Space Between",
                    "Space Around",
                    "Space Evenly",
                ],
                hidden: (props) => props.type != "stack",
            },
            alignH: {
                type: ControlType.Enum,
                defaultValue: "start",
                options: ["start", "center", "end"],
                optionTitles: ["Top", "Center", "Bottom"],
                optionIcons: ["align-top", "align-middle", "align-bottom"],
                displaySegmentedControl: true,
                title: "Align",
                hidden: (props) =>
                    props.type != "stack" || props.direction != "horizontal",
            },
            alignV: {
                type: ControlType.Enum,
                defaultValue: "start",
                options: ["start", "center", "end"],
                optionTitles: ["Left", "Center", "Right"],
                optionIcons: ["align-left", "align-center", "align-right"],
                displaySegmentedControl: true,
                title: "Align",
                hidden: (props) =>
                    props.type != "stack" || props.direction != "vertical",
            },
            wrap: {
                type: ControlType.Boolean,
                defaultValue: false,
                hidden: (props) => props.type != "stack",
            },
            columns: {
                type: ControlType.Enum,
                defaultValue: "fixed",
                options: ["auto", "fixed"],
                optionTitles: ["Auto", "Fixed"],
                displaySegmentedControl: true,
                hidden: (props) => props.type != "grid",
            },
            columnCount: {
                type: ControlType.Number,
                defaultValue: 2,
                min: 1,
                step: 1,
                displayStepper: true,
                title: " ",
                hidden: (props) =>
                    props.type != "grid" || props.columns == "auto",
            },
            gridWidthType: {
                type: ControlType.Enum,
                defaultValue: "min",
                options: ["min", "fixed"],
                optionTitles: ["Min", "Fixed"],
                displaySegmentedControl: true,
                title: "Width",
                hidden: (props) => props.type != "grid",
            },
            gridWidth: {
                type: ControlType.Number,
                defaultValue: 200,
                min: 1,
                step: 1,
                title: " ",
                hidden: (props) => props.type != "grid",
            },
            gridHeightType: {
                type: ControlType.Enum,
                defaultValue: "fit",
                options: ["fit", "fixed"],
                optionTitles: ["Fit", "Fixed"],
                displaySegmentedControl: true,
                title: "Height",
                hidden: (props) => props.type != "grid",
            },
            gridHeight: {
                type: ControlType.Number,
                defaultValue: 200,
                min: 1,
                step: 1,
                title: " ",
                hidden: (props) =>
                    props.type != "grid" || props.gridHeightType != "fixed",
            },
            gridAlign: {
                type: ControlType.Enum,
                defaultValue: "center",
                options: ["start", "center", "end"],
                optionTitles: ["Left", "Center", "Right"],
                displaySegmentedControl: true,
                title: "Align",
                hidden: (props) => props.type != "grid",
            },
            gridAlignV: {
                type: ControlType.Enum,
                defaultValue: "start",
                options: ["start", "center", "end"],
                optionTitles: ["Top", "Center", "Bottom"],
                optionIcons: ["align-top", "align-middle", "align-bottom"],
                displaySegmentedControl: true,
                title: " ",
                hidden: (props) =>
                    props.type != "grid" || props.gridHeightType !== "fit",
            },
            masonryColumns: {
                type: ControlType.Number,
                defaultValue: 3,
                min: 1,
                step: 1,
                displayStepper: true,
                title: "Columns",
                hidden: (props) => props.type !== "masonry",
            },
            masonryItemWidth: {
                type: ControlType.Enum,
                defaultValue: "fill",
                options: ["fit", "fill"],
                optionTitles: ["Fit", "Fill"],
                displaySegmentedControl: true,
                title: "Item Width",
                hidden: (props) => props.type !== "masonry",
            },
            masonryAlign: {
                type: ControlType.Enum,
                defaultValue: "center",
                options: ["start", "center", "end"],
                optionTitles: ["Left", "Center", "Right"],
                displaySegmentedControl: true,
                title: "Align",
                hidden: (props) =>
                    props.type !== "masonry" ||
                    props.masonryItemWidth !== "fit",
            },
            gap: {
                type: ControlType.Number,
                defaultValue: 10,
                min: 0,
                step: 1,
                hidden: (props) => props.type !== "stack",
            },
            gapH: {
                type: ControlType.Number,
                defaultValue: 10,
                min: 0,
                step: 1,
                hidden: (props) =>
                    props.type !== "grid" &&
                    (props.type !== "masonry" || props.masonryColumns === 1),
            },
            gapV: {
                type: ControlType.Number,
                defaultValue: 10,
                min: 0,
                step: 1,
                hidden: (props) =>
                    props.type !== "grid" && props.type !== "masonry",
            },
            padding: {
                type: ControlType.FusedNumber,
                defaultValue: 0,
                toggleKey: "paddingIsMixed",
                toggleTitles: ["All", "Individual"],
                valueKeys: [
                    "paddingTop",
                    "paddingRight",
                    "paddingBottom",
                    "paddingLeft",
                ],
                valueLabels: ["T", "R", "B", "L"],
                min: 0,
            },
        },
    },
    componentConfig: {
        type: ControlType.Object,
        optional: true,
        title: "Component",
        description: " ",
        controls: {
            variant: {
                type: ControlType.String,
                defaultValue: "",
                placeholder: "Variant Name",
                description:
                    "Override the component's variant for responsive design.\n\n*Note:* The component must be the only layer inside the Collection List to use this.",
            },
        },
    },
    pagination: {
        type: ControlType.Boolean,
        defaultValue: false,
    },
    paginationType: {
        type: ControlType.Enum,
        defaultValue: "pagination",
        options: [
            "prevNextButtons",
            "loadMoreButton",
            // "infiniteScroll"
        ],
        optionTitles: [
            "Prev/Next Buttons",
            "Load More Button",
            // "Infinite Scroll",
        ],
        title: "Type",
        displaySegmentedControl: true,
        segmentedControlDirection: "vertical",
        hidden: (props) => !props.pagination,
    },
    itemsPerPage: {
        type: ControlType.Number,
        defaultValue: 4,
        min: 1,
        step: 1,
        displayStepper: true,
        hidden: (props) => !props.pagination,
    },
    scrollUp: {
        type: ControlType.Object,
        optional: true,
        defaultValue: { offset: 0 },
        controls: {
            offset: {
                type: ControlType.Number,
                defaultValue: -32,
                step: 1,
                description:
                    "When the page is changed, scroll up to the top of the Superfields component with an offset in px.",
            },
        },
        hidden: (props) =>
            !props.pagination || props.paginationType != "prevNextButtons",
    },
    filtering: {
        type: ControlType.Boolean,
        defaultValue: false,
    },
    favouriting: {
        type: ControlType.Boolean,
        defaultValue: false,
    },
    cmsCollectionName: {
        type: ControlType.String,
        defaultValue: "",
        placeholder: "CMS Collection Name",
        title: "CMS Collection Name",
        hidden: (props) => !props.favouriting,
    },
    slugFieldName: {
        type: ControlType.String,
        defaultValue: "Slug",
        placeholder: "Slug Field Name",
        hidden: (props) => !props.favouriting,
    },
    favouritesOnly: {
        type: ControlType.Boolean,
        defaultValue: false,
        hidden: (props) => !props.favouriting,
    },
    search: {
        type: ControlType.Boolean,
        defaultValue: false,
    },
    searchFields: {
        type: ControlType.Array,
        defaultValue: ["Title"],
        title: "Fields",
        control: {
            type: ControlType.String,
            placeholder: "CMS Field Name",
        },
        description: "CMS fields to search",
        hidden: (props) => !props.search,
    },
    randomize: {
        type: ControlType.Boolean,
        defaultValue: false,
    },
})

function ComponentVariantSwitch({ child, componentConfig }) {
    const componentProps = child?.props?.children?.props?.children?.props

    // Component types:
    // default: variant prop is 0 levels down
    // links: use ResolveLinks and go 2 levels down
    // child: variant prop is 1 levels down
    let componentType = "default"
    let component = componentProps?.children

    if (typeof component == "function" && componentProps?.links) {
        componentType = "links"
        component = ResolveLinks.render({
            links: componentProps.links,
            children: componentProps.children,
        })
    } else if (!component?.type?.propertyControls) {
        componentType = "child"
    }

    const cData =
        componentType == "links"
            ? component?.props?.children?.[0]?.props?.children?.props?.children
            : componentType == "child"
              ? component?.props?.children
              : component
    if (component && cData) {
        const variantProp = cData.type?.propertyControls?.variant
        if (variantProp) {
            if (componentType == "links") {
                return cloneElement(component, {
                    children: {
                        ...component.props.children[0],
                        props: {
                            ...component.props.children[0].props,
                            children: {
                                ...component.props.children[0].props.children,
                                props: {
                                    ...component.props.children[0].props
                                        .children.props,
                                    children: {
                                        ...cData,
                                        props: {
                                            ...cData.props,
                                            variant: componentConfig.variant,
                                        },
                                    },
                                },
                            },
                        },
                    },
                })
            } else if (componentType == "child") {
                return cloneElement(component, {
                    children: {
                        ...cData,
                        props: {
                            ...cData.props,
                            variant: componentConfig.variant,
                        },
                    },
                })
            } else {
                return cloneElement(component, {
                    variant: componentConfig.variant,
                })
            }
        }
    }

    return child
}

class SeededRNG {
    constructor(seed) {
        this.seed = seed
        this.m = 0x80000000 // 2**31
        this.a = 1103515245
        this.c = 12345
    }

    next() {
        this.seed = (this.a * this.seed + this.c) % this.m
        return this.seed / (this.m - 1)
    }
}

// Fisher-Yates Shuffle using a seeded RNG
function shuffle(array, seed) {
    const rng = new SeededRNG(seed)
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(rng.next() * (i + 1))
        ;[array[i], array[j]] = [array[j], array[i]] // Swap elements
    }
}
